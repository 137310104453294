import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import OTPField from "../../components/OTPField";
import { resendOtp, sendOtp, verifyOtp } from "./actions";

import { makeSelectEnv } from "../../containers/App/selectors";
import {
    makeSelectSendOtpSuccess,
    makeSelectVerifyOtpSuccess,
} from "./selectors";
import { ToastContainer } from "react-toastify";
import Events from "../../utils/events";
import { sendErrorToast } from "../../utils/toaster";

export class CandidateLoginPage extends React.PureComponent {
    // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);
        this.state = {
            otpStep: 1,
            email: "",
            isSignedIn: this.props.auth.loggedIn(),
            resendEnabled: false,
            resendTimer: 0,
            resendOtpSuccess: false,
            redirectUrl:
                this.getQueryStringValue("redirectUrlAdaface") ||
                this.getRedirectUrlFromCookie(),
        };

        if (this.getQueryStringValue("redirectUrlAdaface")) {
            this.setRedirectUrlInCookie(
                this.getQueryStringValue("redirectUrlAdaface")
            );
        }

        Events.sendView({
            page: Events.PAGES.CANDIDATE_LOGIN_PAGE,
        });
    }

    getRedirectUrlFromCookie() {
        return this.props.cookies.get("redirectUrlPostLogin");
    }

    setRedirectUrlInCookie(url) {
        this.props.cookies.remove("redirectUrlPostLogin");
        this.props.cookies.set("redirectUrlPostLogin", url || "", {
            expires: new Date(new Date().getTime() + 10 * 60 * 1000),
            path: "/",
            secure: true,
        });
    }

    removeRedirectUrlFromCookie() {
        this.props.cookies.remove("redirectUrlPostLogin", {
            path: "/",
            expires: new Date(new Date().getTime() - 10 * 60 * 1000),
            secure: true,
        });
    }

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie =
            name + "=" + (value || "") + expires + "; path=/; secure";
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    eraseCookie(name) {
        document.cookie =
            name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure";
    }

    workWithLogin() {
        this.setState({
            isSignedIn: this.props.auth.loggedIn(),
        });

        if (this.props.auth.loggedIn()) {
            this.props.auth.getProfile((profile) => {
                this.setState({
                    profile,
                });

                if (this.state.redirectUrl) {
                    this.removeRedirectUrlFromCookie();
                    Events.sendAction({
                        action: Events.ACTIONS.GENERAL,
                        name: "loginsuccess",
                        page: Events.PAGES.CANDIDATE_LOGIN_PAGE,
                    });
                    setTimeout(() => {
                        window.location = this.state.redirectUrl;
                    }, 200);
                }
            });
        } else {
            this.setState({
                profile: null,
            });
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(
            window.location.search.replace(
                new RegExp(
                    "^(?:.*[&\\?]" +
                        encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
                        "(?:\\=([^&]*))?)?.*$",
                    "i"
                ),
                "$1"
            )
        );
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.auth.logout();
            this.props.auth.reset(
                Events.PAGES.CANDIDATE_LOGIN_PAGE,
                false,
                "auth0",
                {
                    initialScreen:
                        this.getQueryStringValue("show") === "login"
                            ? "login"
                            : "signUp",
                }
            );
            this.unsubscriber = this.props.auth.subscribe(() => {
                this.workWithLogin();
            });
            this.props.auth.login(null, {
                auth: {
                    redirect: true,
                },
            });
        }, 200);
    }

    componentWillUpdate(nextProps, nextState) {}

    componentWillUnmount() {
        this.unsubscriber && this.unsubscriber();
        this.resendInterval && clearInterval(this.resendInterval);
        // this.unregisterAuthObserver();
    }

    componentDidUpdate(prevProps, prevState) {
        // login succeeded via otp
        if (this.props.verifyOtpSuccess && !prevProps.verifyOtpSuccess) {
            this.props.auth.doTokenAuthentication(this.props.verifyOtpSuccess);
        }
        if (this.props.sendOtpSuccess && !prevProps.sendOtpSuccess) {
            this.setState({
                otpStep: this.props.sendOtpSuccess === "success" ? 2 : 1,
                email:
                    this.props.sendOtpSuccess === "success"
                        ? this.state.email
                        : "",
            });
        }

        if (!prevState.resendTimer && this.state.resendTimer) {
            this.resendInterval = setInterval(() => {
                if (this.state.resendTimer > 0) {
                    if (this.state.resendTimer === 1) {
                        this.setState({
                            resendEnabled: true,
                        });
                    }
                    this.setState({
                        resendTimer: this.state.resendTimer - 1,
                    });
                }

                if (this.state.resendTimer === 0) {
                    clearInterval(this.resendInterval);
                }
            }, 1000);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(
            window.location.search.replace(
                new RegExp(
                    "^(?:.*[&\\?]" +
                        encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
                        "(?:\\=([^&]*))?)?.*$",
                    "i"
                ),
                "$1"
            )
        );
    }

    render() {
        const formatSeconds = (seconds) => {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${minutes < 10 ? "0" : ""}${minutes}:${
                remainingSeconds < 10 ? "0" : ""
            }${remainingSeconds}`;
        };

        return (
            <section className="hero is-fullheight candidate-login-page">
                <ToastContainer />
                <div className="hero-body" id="auth0">
                    <div
                        className="column"
                        style={{
                            display: "flex",
                            flex: "1",
                            flexDirection: "column",
                            height: "100%",
                            paddingTop: "20px",
                            paddingLeft: "40px",
                            paddingRight: "40px",
                        }}
                    >
                        {this.state.otpStep === 1 ? (
                            <div
                                className="content"
                                style={{ maxWidth: "520px", margin: "auto" }}
                            >
                                <h1
                                    style={{
                                        fontWeight: 900,
                                        letterSpacing: "-0.75px",
                                        margin: 0,
                                    }}
                                >
                                    {this.getQueryStringValue(
                                        "redirectUrlAdaface"
                                    ).startsWith("/app/")
                                        ? "Enter your email address"
                                        : "Log In to Adaface 👋"}
                                </h1>
                                <br />
                                <p
                                    style={{
                                        marginBottom: "5px",
                                    }}
                                >
                                    <b>
                                        Enter the email address you received the
                                        invite on
                                    </b>
                                </p>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Email address"
                                    value={this.state.email || ""}
                                    onChange={(e) =>
                                        this.setState({
                                            email: e.target.value,
                                        })
                                    }
                                    onKeyPress={(e) => {
                                        if (
                                            e.key === "Enter" &&
                                            this.state.email
                                        ) {
                                            this.props.sendOtp({
                                                emailId: this.state.email,
                                            });
                                            this.setState({
                                                resendTimer: 60,
                                            });
                                        }
                                    }}
                                />
                                <p
                                    style={{
                                        marginTop: "15px",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <b> </b>
                                </p>
                                <div
                                    style={{
                                        marginTop: "15px",
                                        fontWeight: 900,
                                    }}
                                    className={`button is-fullwidth is-medium is-info`}
                                    onClick={() => {
                                        if (!this.state.email) {
                                            sendErrorToast(
                                                "Please enter your email address"
                                            );
                                        } else {
                                            if (
                                                this.state.email !==
                                                this.state.email.trim()
                                            ) {
                                                sendErrorToast(
                                                    "Email address cannot contain spaces"
                                                );
                                            } else {
                                                this.props.sendOtp({
                                                    emailId: this.state.email,
                                                });
                                                this.setState({
                                                    resendTimer: 60,
                                                });
                                            }
                                        }
                                    }}
                                >
                                    <span>Sign Up/ Log In</span>
                                </div>
                                <p
                                    className="is-size-7"
                                    style={{ marginTop: "5px" }}
                                >
                                    By clicking Sign Up, you agree to Adaface’s{" "}
                                    <a>Terms of Use</a> and{" "}
                                    <a>Privacy Policy</a>
                                </p>
                            </div>
                        ) : null}
                        <div
                            style={{
                                display:
                                    this.state.otpStep == 2 ? "flex" : "none",
                                maxWidth: "520px",
                                margin: "auto",
                                flexDirection: "column",
                            }}
                        >
                            <h1
                                style={{
                                    fontWeight: 900,
                                    letterSpacing: "-0.75px",
                                    margin: 0,
                                }}
                            >
                                Check your email for a magic code.
                            </h1>
                            <br />
                            <p
                                style={{
                                    marginBottom: "5px",
                                    alignSelf: "center",
                                }}
                            >
                                <b>
                                    We’ve sent a 6-character code to{" "}
                                    {this.state.email}.
                                </b>
                            </p>
                            <div style={{ display: "flex" }}>
                                <OTPField
                                    submittedOTP={(otp) => {
                                        this.props.verifyOtp({
                                            code: otp,
                                            emailId: this.state.email,
                                        });
                                    }}
                                    inputStyles={{
                                        textAlign: "center",
                                        width: "45px",
                                        appearance: "none",
                                        borderRadius: "5px",
                                        position: "relative",
                                        padding: "10px 10px",
                                        placeholderColor: "#ccc",
                                        margin: "5px",
                                        borderColor: "#000",
                                        borderWidth: "2px",
                                        borderStyle: "solid",
                                    }}
                                />
                            </div>
                            <p
                                className="is-size-6"
                                style={{
                                    marginTop: "5px",
                                    alignSelf: "center",
                                }}
                            >
                                Can’t find the code? Please check your spam
                                folder.
                            </p>
                            {!this.state.resendOtpSuccess ? (
                                <a
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    className={`resend-otp-${
                                        this.state.resendEnabled
                                            ? "enabled"
                                            : "disabled"
                                    } is-size-6`}
                                    onClick={() => {
                                        if (this.state.resendEnabled) {
                                            this.props.resendOtp({
                                                emailId: this.state.email,
                                            });
                                            this.setState({
                                                resendOtpSuccess: true,
                                                resendEnabled: false,
                                            });
                                        }
                                    }}
                                >
                                    <u style={{ paddingRight: "5px" }}>
                                        Resend code
                                    </u>
                                    {this.state.resendTimer > 0 ? (
                                        <p>
                                            {formatSeconds(
                                                this.state.resendTimer
                                            )}
                                        </p>
                                    ) : null}
                                </a>
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

CandidateLoginPage.propTypes = {};

export function mapDispatchToProps(dispatch) {
    return {
        // loginUser: (d) => dispatch(loginUser(d)),
        sendOtp: (d) => dispatch(sendOtp(d)),
        verifyOtp: (d) => dispatch(verifyOtp(d)),
        resendOtp: (d) => dispatch(resendOtp(d)),
    };
}

const mapStateToProps = createStructuredSelector({
    env: makeSelectEnv(),
    // companyId: makeSelectCompanyId(),
    verifyOtpSuccess: makeSelectVerifyOtpSuccess(),
    sendOtpSuccess: makeSelectSendOtpSuccess(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CandidateLoginPage);
